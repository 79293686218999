/** @jsx jsx */
import styled from "@emotion/styled";
import { jsx, Text } from "theme-ui";

const FrameImage = styled.img`
  border-radius: 34px;
  border: 7px solid #d8d8d8;
  height: 190px;
  padding: 50px;
`;

const Figure = styled.figure`
  margin: 0;
  max-width: auto;
  text-align: center;
`;

const FramedImage = ({
  altText,
  captionText,
  imageSrc,
  mb,
  ml,
  mr,
  mt,
  mx,
  my,
  ...props
}) => (
  <Figure
    {...props}
    sx={{ mb, ml, mr, mt, mx, my, position: "relative", pb: 10 }}
  >
    <FrameImage src={imageSrc} alt={altText} />
    <Text
      as="figcaption"
      mt={[5, null, null, 8]}
      sx={{
        fontSize: [2, null, null, 2],
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        whiteSpace: "nowrap",
      }}
    >
      {captionText}
    </Text>
  </Figure>
);

export default FramedImage;
