/** @jsx jsx */
import React, { useRef, useState } from "react"; //eslint-disable-line no-unused-vars
import { jsx } from "theme-ui";

const Video = ({ mp4, ogg, webm, poster, ...props }) => {
  const videoRef = useRef(null);

  const handleClick = e => {
    e.preventDefault();
    videoRef.current.muted = !videoRef.current.muted;
  };

  return (
    <div sx={{ position: "relative", display: "block" }}>
      <video
        // onPause={() => setIsPlaying(false)}
        // onPlay={() => setIsPlaying(true)}
        controls={"controls"}
        poster={poster}
        ref={videoRef}
        autoPlay={true}
        onClick={handleClick}
        muted="muted"
        loop
        sx={{
          outline: "none",
          width: "100%",
          verticalAlign: "middle",
          ":hover": {
            "> button": {
              opacity: 1,
            },
          },
        }}
      >
        <source src={mp4} type="video/mp4" />
        {ogg && <source src={ogg} type="video/ogg" />}
        {webm && <source src={webm} type="video/ogg" />}
      </video>
      {/* <div
          sx={{
            backgroundColor: "black",
            bottom: 0,
            display: "flex",
            p: 2,
            left: 0,
            position: "absolute",
            width: "100%",
          }}
        >
          <button>play</button>
        </div> */}
      {/* {!isPlaying && (
        <button
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            appearance: "none",
            border: "5px solid",
            borderColor: "primary",
            borderRadius: "100%",
            background: "white",
            boxShadow: "1px 1px 10px -2px black",
            height: "85px",
            width: "85px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: isPlaying ? 0 : 1,
          }}

        >
          <span
            sx={{
              display: "block",

              borderTop: "20px solid transparent",
              borderBottom: "20px solid transparent",
              borderLeft: "45px solid",
              marginRight: "-15px",
              borderLeftColor: "primary",
            }}
          />
          <span
            sx={{
              position: "absolute",
              left: "-10000px",
              top: "auto",
              width: "1px",
              height: "1px",
              overflow: "hidden",
            }}
          >
            Play/Pause
          </span>
        </button>
      )} */}
    </div>
  );
};
export default Video;
