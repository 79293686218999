/** @jsx jsx */
import React from "react"; //eslint-disable-line no-unused-vars
import { Box, Container, Flex, Heading, Image, jsx } from "theme-ui";
import { graphql } from "gatsby";

import MarkdownRenderer from "../components/MarkdownRenderer";
import Footer from "../components/Footer";
import FramedImage from "../components/FramedImage";
import Header from "../components/Header";
import LinkButton from "../components/LinkButton";

import PageData from "../../content/pages/platform.yml";
import Video from "../components/Video";
import SEO from "../components/SEO";

// import DousingMp4 from ""

export const query = graphql`
  query {
    pdfLink: file(relativePath: { eq: "How-SAA-Works.pdf" }) {
      id
      publicURL
    }
    heroBackgroundDots: file(relativePath: { eq: "hero-background-dots.svg" }) {
      id
      publicURL
      extension
    }
    heroDots: file(relativePath: { eq: "hero-dots-2.svg" }) {
      id
      publicURL
      extension
    }
    ourClientsGif: file(relativePath: { eq: "our-clients-gif.gif" }) {
      id
      publicURL
      extension
    }
  }
`;

const Circle = props => (
  <div>
    <span
      className={props.className}
      sx={{
        background: ["#195858", null, "#A8D5D6"],
        borderRadius: "100%",
        display: "block",
        height: "40px",
        marginBottom: 5,
        position: "relative",
        width: "40px",
        zIndex: 2,
      }}
    ></span>
  </div>
);

const Platform = ({ data }) => {
  const line = {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    left: ["50%", null, "initial"],
    position: ["absolute", null, "relative"],
    top: [0, null, "initial"],
    transform: ["translateX(-50%)", null, "none"],
    zIndex: 1,
    ":before": {
      backgroundColor: ["#0a4a4a", null, "#407979"],
      content: '""',
      height: "100%",
      left: "50%",
      position: "absolute",
      top: 0,
      transform: "translateX(-50%)",
      width: "12px",
      zIndex: "1",
    },
  };

  return (
    <>
      <SEO title="The Dosis Platform" />

      <Header
        heroImage={data.heroDots.publicURL}
        heroTitle={PageData.title}
        heroVariant="medium"
      />

      <Container variant="containerLg" id="strategic-anemia">
        <Flex sx={{ my: 13, flexWrap: "wrap", alignItems: "center" }}>
          <Box as="section" sx={{ flex: ["1 auto", null, "0 0 50%"] }}>
            <Heading
              as="h1"
              sx={{
                textAlign: "left",
                fontSize: [
                  PageData.mobile_font_sizes.title,
                  null,
                  null,
                  PageData.desktop_font_sizes.title,
                ],
              }}
            >
              {PageData.block_1_title}
            </Heading>

            <MarkdownRenderer
              small={PageData.mobile_font_sizes.copy}
              large={PageData.desktop_font_sizes.copy}
              sx={{
                mt: 5,
                pr: 16,
                textAlign: PageData?.block_1_alignment
                  ? PageData?.block_1_alignment
                  : "left",
              }}
            >
              {PageData.block_1_intro}
            </MarkdownRenderer>
          </Box>

          <Box
            as="section"
            backgroundColor="white"
            // mt={13}
            sx={{ flex: ["1 auto", null, "0 0 50%"] }}
          >
            <Video
              mp4={"/media/Dosis.m4v"}
              webm={"/media/Dosis.webm"}
              poster={"/media/dosisposter.png"}
            />
          </Box>
        </Flex>
      </Container>

      <Box
        as="section"
        backgroundColor="secondary"
        color="white"
        py={[15, null, 18]}
      >
        <Container variant="containerLg">
          <Flex
            sx={{
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: ["center", null, null, "space-between"],
            }}
          >
            <Box
              sx={{
                flex: ["0 0 100%", null, null, "0 0 50%"],
                pr: [2, null, null, null, null, 18],
              }}
            >
              <Heading
                as="h1"
                sx={{
                  fontSize: [
                    PageData.mobile_font_sizes.title,
                    null,
                    null,
                    PageData.desktop_font_sizes.title,
                  ],
                }}
              >
                {PageData.block_2_title}
              </Heading>

              <MarkdownRenderer
                small={PageData.mobile_font_sizes.copy}
                large={PageData.desktop_font_sizes.copy}
                sx={{
                  mt: 5,
                  textAlign: PageData?.block_2_alignment
                    ? PageData?.block_2_alignment
                    : "left",
                }}
              >
                {PageData.block_2_intro}
              </MarkdownRenderer>
            </Box>

            <Box
              mt={[13, null, null, 0]}
              sx={{
                flex: ["0 0 100%", null, null, " 0 0 50%"],
                pl: 10,
                textAlign: "center",
              }}
            >
              <Image
                src={PageData.block_2_image}
                alt={`${PageData.block_2_title} Chart`}
                sx={{ borderRadius: "20px" }}
              />
            </Box>
          </Flex>
        </Container>
      </Box>

      <Box as="section" py={[15, null, 18]} backgroundColor="lightGray">
        <Container>
          <Flex sx={{ justifyContent: "center" }}>
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Heading
                as="h1"
                mb={8}
                sx={{
                  textAlign: "center",
                  fontSize: [
                    PageData.mobile_font_sizes.title,
                    null,
                    null,
                    PageData.desktop_font_sizes.title,
                  ],
                }}
              >
                {PageData.block_3_title}
              </Heading>

              <MarkdownRenderer
                small={PageData.mobile_font_sizes.copy}
                large={PageData.desktop_font_sizes.copy}
                sx={{
                  textAlign: PageData?.block_3_alignment
                    ? PageData?.block_3_alignment
                    : "center",
                }}
              >
                {PageData.block_3_intro}
              </MarkdownRenderer>

              <Flex
                mt={[13, 17]}
                sx={{
                  flexWrap: "wrap",
                  flexDirection: ["column", "column", "row"],
                  margin: "0 auto",
                  justifyContent: "center",
                  alignItems: ["center", "center", "flex-start"],
                }}
              >
                {PageData.block_3_columns.map((col, i) => (
                  <FramedImage
                    altText={col.title}
                    captionText={col.title}
                    imageSrc={col.image}
                    mx={[0, null, 14]}
                    sx={{
                      px: 6,
                      mb: [6, null, 0],
                      mt: [6, null, 0],
                    }}
                    key={i}
                  />
                ))}
              </Flex>

              <LinkButton
                buttonPos="center"
                buttonText="request a demo"
                linkTo="/request-a-demo"
                mt={17}
                textTransform="uppercase"
              />
            </Box>
          </Flex>
        </Container>
      </Box>

      <Box
        id="future-modules"
        as="section"
        backgroundColor="secondary"
        color="white"
      >
        <Container>
          <Flex
            sx={{
              flexWrap: "wrap",
              justifyContent: ["start", "center"],
              position: "relative",
            }}
          >
            <Flex
              sx={{
                flexWrap: "wrap",
                justifyContent: ["start", "center"],
                position: "relative",
                width: "100%",
              }}
            >
              <Box sx={{ flex: ["0 0 25%"], ...line }}>
                <Circle sx={{ mt: 14 }} />
              </Box>
              <Box
                sx={{
                  flex: ["0 0 100%", "0 0 75%"],
                  mt: 14,
                  zIndex: 2,
                  position: "relative",
                }}
              >
                <Heading
                  sx={{ fontSize: [40, null, null, 60] }}
                  id="future-modules"
                >
                  Future Modules
                </Heading>
              </Box>
            </Flex>

            {PageData.future_modules.map((module, i) => (
              <Flex
                sx={{ position: "relative", justifyContent: "center" }}
                key={i}
              >
                <Box sx={{ flex: ["0 0 25%"], ...line, pt: 14 }}>
                  <Box
                    sx={{
                      alignItems: "center",
                      backgroundColor: ["#265e5e", null, "white"],
                      borderRadius: "50%",
                      display: "flex",
                      height: "100px",
                      justifyContent: "center",
                      mx: "auto",
                      position: "relative",
                      width: "100px",
                      zIndex: 2,
                    }}
                  >
                    <Image
                      sx={{ opacity: [0.2, null, 1], height: 60 }}
                      src={module.icon}
                    />
                  </Box>
                  <Circle sx={{ mt: 14 }} />
                </Box>

                <Box
                  sx={{
                    flex: ["0 0 100%", "0 0 75%"],
                    my: 14,
                    zIndex: 2,
                    position: "relative",
                  }}
                >
                  <Heading
                    as="h2"
                    mb={5}
                    sx={{
                      fontSize: [
                        PageData.mobile_font_sizes.title,
                        null,
                        null,
                        PageData.desktop_font_sizes.title,
                      ],
                    }}
                  >
                    {module.title}
                  </Heading>
                  <MarkdownRenderer
                    small={PageData.mobile_font_sizes.copy}
                    large={PageData.desktop_font_sizes.copy}
                    sx={{
                      textAlign: module?.content_alignment
                        ? module?.content_alignment
                        : "left",
                    }}
                  >
                    {module.content}
                  </MarkdownRenderer>
                </Box>
              </Flex>
            ))}
          </Flex>
        </Container>
      </Box>

      <Footer />
    </>
  );
};

export default Platform;
